import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Toast } from "primereact/toast";

import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import "./order-information.scss";
import phone from "../../assets/img/phone.svg";
import location from "../../assets/img/location.svg";
import lock from "../../assets/img/lock.svg";
import heartEye from "../../assets/img/heart-eye.svg";
import glowSmile from "../../assets/img/glow-smile.svg";
import pidgeLogo from "../../assets/img/pidge-logo.svg";
import sad from "../../assets/img/sad.svg";
import riderProfileLogo from "../../assets/img/rider-profile-logo.svg";
import greenTickOutlined from "../../assets/img/green-tick-outlined.svg";
import disableTick from "../../assets/img/disable-tick.svg";

import {
  orderStatusInformation,
  trip_final_status,
  trip_initial_status,
} from "../../pages/NewHomePage/utils";
import moment from "moment";
import { Menu } from "primereact/menu";
import { saveFeedbackInfomation } from "../../services/api.service";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { TOAST_MESSAGE_TYPE_SUCCESS } from "../../static/strings";
import ReactGA from "react-ga4";
import utils from "../../services/utils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Text from "../common/Text";
import theme from "../../services/theme";
import useViewport from "../../hooks/use-viewport.hook";

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: "slick-dots slick-dots-custom",
};

const OrderDetails = ({
  deliveryInformation,
  getTrackingDetails,
  configs,
  support,
}) => {
  const { width } = useViewport();
  const [checked, setChecked] = useState(false);
  const [feedback_value, set_feedback_value] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sad_image_highlight, set_sad_image_highlight] = useState(false);
  const [smile_image_highlight, set_smile_image_highlight] = useState(false);
  const [eye_heart_image_highlight, set_eye_heart_image_highlight] =
    useState(false);
  const menu = React.useRef(null);

  const query = useLocationQuery();
  const toastRef = React.useRef();

  const orderId = deliveryInformation?.reference_id
    ? deliveryInformation?.reference_id
    : deliveryInformation?.dd_channel?.order_id
    ? deliveryInformation?.dd_channel?.order_id
    : deliveryInformation?.id;

  const handleEmogies = (emogi) => {
    if (
      !_.has(deliveryInformation, "customer_feedback") &&
      _.has(deliveryInformation, "fulfillment") &&
      show_feedback_form
    ) {
      ReactGA.event({
        category: "User",
        action: "Clicked on Feedback emoji",
      });
      if (emogi === "not_good") {
        set_sad_image_highlight(true);
        set_smile_image_highlight(false);
        set_eye_heart_image_highlight(false);
      } else if (emogi === "ok") {
        set_sad_image_highlight(false);
        set_smile_image_highlight(true);
        set_eye_heart_image_highlight(false);
      } else if (emogi === "great") {
        set_sad_image_highlight(false);
        set_smile_image_highlight(false);
        set_eye_heart_image_highlight(true);
      } else {
        set_sad_image_highlight(false);
        set_smile_image_highlight(false);
        set_eye_heart_image_highlight(false);
      }
    } else {
      return;
    }
  };

  const handleBackToAllEmogis = () => {
    set_sad_image_highlight(false);
    set_smile_image_highlight(false);
    set_eye_heart_image_highlight(false);
  };

  const handleFeedback = async () => {
    setIsLoading(true);
    const data = {
      text: feedback_value,
      id: deliveryInformation.id,
    };
    if (
      sad_image_highlight ||
      smile_image_highlight ||
      eye_heart_image_highlight
    ) {
      data.experience = sad_image_highlight ? 1 : smile_image_highlight ? 2 : 3;
    }
    if (checked) {
      data.requestCallBack = true;
    }
    try {
      const response = await saveFeedbackInfomation(data);
      if (response.status === 200) {
        toastRef.current.show({
          severity: TOAST_MESSAGE_TYPE_SUCCESS,
          summary: "Success",
          detail: "Your feedback is submitted successfully",
        });
        setIsLoading(false);
        getTrackingDetails();
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const show_feedback_form = trip_final_status.includes(
    _.get(deliveryInformation, "fulfillment.status", "")
  );

  const show_inactive_details =
    deliveryInformation?.status === 1 ||
    deliveryInformation?.status === 2 ||
    trip_initial_status.includes(
      _.get(deliveryInformation, "fulfillment.status", "")
    );

  const emogisDisplay = sad_image_highlight ? (
    <div className="align-items-center">
      <div
        className={`emogis-highlight-background text-center cursor-pointer`}
        onClick={handleBackToAllEmogis}
      >
        <img src={sad} alt="sad" width={50} height={50} />
        <div className="feedback-infomation">Not Good</div>
      </div>
      <div className="padding-left-8">
        <p className="emogies-feedback-information">
          We are sorry you did not have a good experience.{" "}
        </p>
        <div className="emogies-feedback-subheading">
          Please tell us how to improve
        </div>
      </div>
    </div>
  ) : smile_image_highlight ? (
    <div className="align-items-center">
      <div
        className={`emogis-highlight-background text-center cursor-pointer`}
        onClick={handleBackToAllEmogis}
      >
        <img src={glowSmile} alt="glow-smile" width={50} height={50} />
        <div className="feedback-infomation">Nice</div>
      </div>
      <div className="padding-left-8">
        <p className="emogies-feedback-information">
          We are happy to deliver your package to you!
        </p>
        <div className="emogies-feedback-subheading">
          Please tell us how to improve
        </div>
      </div>
    </div>
  ) : eye_heart_image_highlight ? (
    <div className="align-items-center">
      <div
        className={`emogis-highlight-background text-center cursor-pointer`}
        onClick={handleBackToAllEmogis}
      >
        <img src={heartEye} alt="heart-eye" width={50} height={50} />
        <div className="feedback-infomation">Nice</div>
      </div>
      <div className="padding-left-8">
        <p className="emogies-feedback-information">
          We are delighted to see you delighted!!
        </p>
        <div className="emogies-feedback-subheading">
          Tell us what you loved
        </div>
      </div>
    </div>
  ) : (
    <>
      <div
        className={`${
          !_.has(deliveryInformation, "customer_feedback") &&
          _.has(deliveryInformation, "fulfillment") &&
          show_feedback_form
            ? "emogis-highlight-blur cursor-pointer"
            : "emogis-feedback-exist"
        } text-center`}
        onClick={() => {
          handleEmogies("not_good");
        }}
      >
        <img src={sad} alt="sad" width={50} height={50} />
        <div className="feedback-infomation">Not Good</div>
      </div>
      <div
        className={`${
          !_.has(deliveryInformation, "customer_feedback") &&
          _.has(deliveryInformation, "fulfillment") &&
          show_feedback_form
            ? "emogis-highlight-blur cursor-pointer"
            : "emogis-feedback-exist"
        } text-center`}
        onClick={() => {
          handleEmogies("ok");
        }}
      >
        <img src={glowSmile} alt="smile" width={50} height={50} />
        <div className="feedback-infomation">Nice</div>
      </div>
      <div
        className={`${
          !_.has(deliveryInformation, "customer_feedback") &&
          _.has(deliveryInformation, "fulfillment") &&
          show_feedback_form
            ? "emogis-highlight-blur cursor-pointer"
            : "emogis-feedback-exist"
        } text-center`}
        onClick={() => {
          handleEmogies("great");
        }}
      >
        <img src={heartEye} alt="heart-eyes" width={50} height={50} />
        <div className="feedback-infomation">Loved it!</div>
      </div>
    </>
  );

  const partner_img = utils.get_partner_img(
    _.get(deliveryInformation, "fulfillment.channel.name", "")
  );

  const is_dilivered = useMemo(() => {
    return (
      _.has(deliveryInformation, "fulfillment") &&
      _.get(deliveryInformation, "fulfillment.status", "") === "DELIVERED"
    );
  }, [deliveryInformation]);

  const is_shipped = useMemo(() => {
    return (
      _.has(deliveryInformation, "fulfillment") &&
      (_.get(deliveryInformation, "fulfillment.status", "") ===
        "OUT_FOR_DELIVERY" ||
        _.get(deliveryInformation, "fulfillment.status", "") ===
          "REACHED_DELIVERY")
    );
  }, [deliveryInformation]);
  return (
    <div className="order-information-content">
      <Text
        bold
        style={{ color: theme.colors.primary }}
        className={"order-information"}
      >
        {deliveryInformation?.status === 1 || deliveryInformation?.status === 2
          ? "Order Confirmed"
          : orderStatusInformation(
              _.get(deliveryInformation, "fulfillment.status", "")
            )}
      </Text>
      <div className="order-status">Order Status</div>
      {!_.isEmpty(_.get(deliveryInformation, "fulfillment.rider", null)) && (
        <div className="order-detail-wrapper" style={{ marginTop: 16 }}>
          <div className="delivery-information-box" style={{ height: 140 }}>
            <div className="delivery-tracking-info">
              Delivery Partner Tracking ID
            </div>
            <div
              className="space-between-container"
              style={{
                flexWrap: "wrap",
                display: "flex",
                alignItems: "center",
                height: 100,
              }}
            >
              {deliveryInformation.fulfillment?.channel && (
                <div className="align-items-center">
                  <div style={{ marginTop: 5 }}>
                    {_.isEmpty(partner_img) ? (
                      <p>
                        {_.get(
                          deliveryInformation,
                          "fulfillment.channel.name",
                          ""
                        )}
                      </p>
                    ) : (
                      <img src={partner_img} height={18} />
                    )}
                  </div>
                  <div style={{ padding: "0px 4px" }}>|</div>
                  <div className="location">
                    <img src={location} alt="location" />{" "}
                    {_.get(deliveryInformation, "fulfillment.channel.order_id")}
                  </div>
                </div>
              )}
              <div className="align-items-center" style={{ paddingTop: 8 }}>
                <div className="align-items-center">
                  <img
                    src={
                      !_.isEmpty(
                        _.get(
                          deliveryInformation,
                          "fulfillment.rider.profile_pic",
                          ""
                        )
                      )
                        ? _.get(
                            deliveryInformation,
                            "fulfillment.rider.profile_pic",
                            ""
                          )
                        : riderProfileLogo
                    }
                    alt="profile"
                    width={40}
                    style={{ borderRadius: "50%" }}
                    height={40}
                  />
                </div>

                <div
                  className="information"
                  style={{ paddingLeft: 8, paddingRight: 8 }}
                >
                  {_.get(deliveryInformation, "fulfillment.rider.name", "")}
                </div>
                {window.innerWidth < 767 ? (
                  <a
                    href={`tel:${_.get(
                      deliveryInformation,
                      "fulfillment.rider.mobile",
                      ""
                    )}`}
                  >
                    <div>
                      <img src={phone} alt="phone" width={20} height={20} />
                    </div>
                  </a>
                ) : (
                  <>
                    <Menu
                      model={[
                        {
                          label: `${_.get(
                            deliveryInformation,
                            "fulfillment.rider.mobile",
                            ""
                          )}`,
                        },
                      ]}
                      popup
                      ref={menu}
                      style={{ width: "136px", padding: 0 }}
                    />
                    <div>
                      <img
                        src={phone}
                        alt="phone"
                        width={20}
                        height={20}
                        className="cursor-pointer"
                        onClick={(e) => {
                          ReactGA.event({
                            category: "User",
                            action: "Clicked on Delivery Partner Mobile",
                          });
                          menu.current.toggle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {configs.marketing_content && (
            <div
              style={{
                cursor: "pointer",
                backgroundColor: theme.colors.lightGrey5,
                height: 140,
                borderRadius: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 300,
              }}
            >
              {configs.marketing_content.length > 1 && (
                <Slider
                  {...slider_settings}
                  style={{
                    height: 140,
                    width: width < 600 ? "calc(100vw - 65px)" : 300,
                  }}
                  autoplay={true}
                  autoplaySpeed={3000}
                >
                  {_.map(configs.marketing_content, (item) => {
                    return (
                      <div
                        onClick={() => {
                          if (item.cta_link) {
                            window.open(item.cta_link, "_blank", "noreferrer");
                            window.location.reload();
                          }
                        }}
                        className="justify-content-center"
                        style={{
                          display: "flex !important",
                          backgroundColor: theme.colors.red,
                        }}
                      >
                        <img
                          src={item.image}
                          style={{
                            height: 140,
                            width: "auto",
                            maxWidth: 300,
                          }}
                        ></img>
                      </div>
                    );
                  })}
                </Slider>
              )}
              {configs.marketing_content.length == 1 && (
                <div
                  onClick={() => {
                    if (configs.marketing_content[0].cta_link) {
                      window.open(
                        configs.marketing_content[0].cta_link,
                        "_blank",
                        "noreferrer"
                      );
                      window.location.reload();
                    }
                  }}
                  className="justify-content-center"
                  style={{
                    display: "flex !important",
                    backgroundColor: theme.colors.red,
                  }}
                >
                  <img
                    src={configs.marketing_content[0].image}
                    style={{
                      height: 140,
                      width: "auto",
                      maxWidth: 300,
                    }}
                  ></img>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {support.length > 0 && (
        <div className="delivery-information-box" style={{ marginTop: 10 }}>
          <Text
            semi
            style={{
              color: theme.colors.darkGrey2,
              fontSize: 12,
              textAlign: "center",
              paddingBottom: 0,
            }}
          >
            {`Support: ${support[0].mobile} | ${support[0].email}`}
          </Text>
        </div>
      )}
      <div className="p-grid">
        <div className="p-col-12 p-md-12 p-lg-8 p-xl-8">
          <div className="delivery-information-box">
            <div
              className="space-between-container"
              style={{
                borderBottom: "1.46697px solid #E5E5E5",
                paddingBottom: 8,
              }}
            >
              <div
                className="delivery-tracking-info"
                style={{ border: "unset" }}
              >
                Order & Delivery Details
              </div>
              {configs.show_order_amount && (
                <div className="payment-status">
                  {deliveryInformation?.cod_amount ? "COD" : "Prepaid"}
                </div>
              )}
            </div>
            <div className="space-between-container" style={{ paddingTop: 8 }}>
              <div style={{ padding: "0px 2px" }}>
                <div className="order-info">
                  {orderId.length < 10
                    ? orderId
                    : `${orderId.substring(0, 5)}...${orderId.substring(
                        orderId.length - 5
                      )}`}
                </div>
                <div className="order-status">Order Id</div>
              </div>
              <div style={{ padding: "0px 2px" }}>
                <div className="order-info">
                  {moment(deliveryInformation.created_at).format("DD MMM YYYY")}
                </div>
                <div className="order-status">Order Date</div>
              </div>
              {configs.show_order_amount ? (
                <div style={{ padding: "0px 2px" }}>
                  <div
                    className="order-info"
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    ₹{" "}
                    {deliveryInformation?.cod_amount > 0
                      ? _.get(deliveryInformation, "cod_amount", 0).toFixed(2)
                      : _.get(deliveryInformation, "bill_amount", 0).toFixed(2)}
                  </div>
                  <div className="order-status">
                    {deliveryInformation?.cod_amount > 0
                      ? "Payment"
                      : "Order Value"}
                  </div>
                </div>
              ) : (
                <div style={{ padding: "0px 2px" }}></div>
              )}
            </div>
            <div style={{ paddingTop: 12 }}>
              <div className="order-info">
                {`${_.get(
                  deliveryInformation,
                  "customer_detail.address.address_line_1",
                  ""
                )} ${_.get(
                  deliveryInformation,
                  "customer_detail.address.address_line_2",
                  ""
                )} ${_.get(
                  deliveryInformation,
                  "customer_detail.address.state",
                  ""
                )} ${_.get(
                  deliveryInformation,
                  "customer_detail.address.pincode",
                  ""
                )}`}
              </div>
              <div className="order-status">Delivery Address</div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-12 p-lg-4 p-xl-4">
          <div
            className={`delivery-information-box ${
              is_shipped ? "" : "align-items-center"
            } `}
          >
            <div
              className={`${is_shipped ? "" : "align-items-center m-auto"} `}
            >
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  ...(is_shipped && {
                    display: "flex",
                    gap: 4,
                    justifyContent: "center",
                    alignItems: "center",
                  }),
                }}
              >
                <img
                  src={lock}
                  alt="lock"
                  width={is_shipped ? 12 : 32}
                  height={is_shipped ? 15 : 38}
                />
                <div
                  className="secure-delivery-info"
                  style={{ ...(is_shipped && { marginTop: 0 }) }}
                >
                  {is_dilivered
                    ? "We securely delivered your package with"
                    : is_shipped
                    ? "Secure Delivery OTP"
                    : "Your Delivery is Secured!"}
                </div>
                {is_dilivered && (
                  <div style={{ marginTop: 4 }}>
                    {_.isEmpty(partner_img) ? (
                      <p>
                        {_.get(
                          deliveryInformation,
                          "fulfillment.channel.name",
                          ""
                        )}
                      </p>
                    ) : (
                      <img height={16} src={partner_img} alt="company-logo" />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                marginTop: "1.75rem",
                justifyContent: "center",
              }}
            >
              {_.map(
                is_shipped &&
                  deliveryInformation?.fulfillment?.drop?.otp?.split(""),
                (digit, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid lightgray", // Assuming `light_gray` refers to `lightgray`
                      padding: "0.375rem 0.75rem", // px-3 (0.75rem), py-1.5 (0.375rem)
                      borderRadius: "0.375rem", // rounded-md = 0.375rem
                      fontWeight: "bold",
                      borderWidth: 1,
                    }}
                  >
                    <p
                      className="font-custom font-semibold"
                      style={{ fontSize: 14 }}
                    >
                      {digit}
                    </p>
                  </div>
                )
              )}
            </div>
            {is_shipped && (
              <div
                style={{
                  color: "var(--Neutrals-N2, #666)",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginTop: "1.5rem",
                }}
              >
                Share OTP with the delivery executive.
              </div>
            )}
          </div>
        </div>
      </div>
      {configs.show_product_detail &&
        deliveryInformation.products.length > 0 && (
          <div className={"product-detail-wrap"}>
            <Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
              Product Details
            </Text>
            <div
              style={{
                marginTop: 8,
                marginBottom: 8,
                border: `1px solid ${theme.colors.lightGrey5}`,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {_.map(
                deliveryInformation.products,
                (product_item, product_index) => (
                  <div
                    key={`prod_item_${product_index}`}
                    className="product-item"
                    style={{
                      marginRight:
                        product_index % 2 === 0 &&
                        deliveryInformation.products.length > 1
                          ? 12
                          : 0,
                    }}
                  >
                    <Text medium style={{ fontSize: 14 }}>
                      {product_item.name}
                    </Text>
                    <Text
                      medium
                      style={{ fontSize: 10, color: theme.colors.darkGrey2 }}
                    >
                      Qty: {product_item.quantity}
                    </Text>
                  </div>
                )
              )}
              {deliveryInformation.products.length === 1 && (
                <div style={{ flex: "50%" }}></div>
              )}
            </div>
          </div>
        )}
      <div className="delivery-information-box">
        {_.has(deliveryInformation, "fulfillment") && show_inactive_details ? (
          <div>
            <div className="feedback-infomation">
              How was your delivery experience?
            </div>
            <div className="active-soon-container">
              <div>Active Soon!</div>
              <div className="feedback-subinformation-margin">
                Feedback form will be available post order completion.
              </div>
            </div>
          </div>
        ) : (
          <div className="p-grid">
            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
              <div className="feedback-infomation">
                How was your delivery experience?
              </div>
              {_.has(deliveryInformation, "customer_feedback") &&
              _.has(deliveryInformation.customer_feedback, "experience") ? (
                <>
                  {deliveryInformation?.customer_feedback?.experience === 1 ? (
                    <div className="align-items-center">
                      <div
                        className={`emogis-highlight-background text-center`}
                        onClick={handleBackToAllEmogis}
                      >
                        <img src={sad} alt="sad" width={50} height={50} />
                        <div className="feedback-infomation">Not Good</div>
                      </div>
                      <div className="padding-left-8">
                        <p className="emogies-feedback-information">
                          We are sorry you did not have a good experience.{" "}
                        </p>
                        <div className="emogies-feedback-subheading">
                          Please tell us how to improve
                        </div>
                      </div>
                    </div>
                  ) : deliveryInformation?.customer_feedback?.experience ===
                    2 ? (
                    <div className="align-items-center">
                      <div
                        className={`emogis-highlight-background text-center`}
                        onClick={handleBackToAllEmogis}
                      >
                        <img
                          src={glowSmile}
                          alt="glow-smile"
                          width={50}
                          height={50}
                        />
                        <div className="feedback-infomation">Nice</div>
                      </div>
                      <div className="padding-left-8">
                        <p className="emogies-feedback-information">
                          We are happy to deliver your package to you!
                        </p>
                        <div className="emogies-feedback-subheading">
                          Please tell us how to improve
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="align-items-center">
                      <div
                        className={`emogis-highlight-background text-center`}
                        onClick={handleBackToAllEmogis}
                      >
                        <img
                          src={heartEye}
                          alt="heart-eye"
                          width={50}
                          height={50}
                        />
                        <div className="feedback-infomation">Nice</div>
                      </div>
                      <div className="padding-left-8">
                        <p className="emogies-feedback-information">
                          We are delighted to see you delighted!!
                        </p>
                        <div className="emogies-feedback-subheading">
                          Tell us what you loved
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="space-evenly-container"
                  style={{ paddingTop: 28 }}
                >
                  {emogisDisplay}
                </div>
              )}
            </div>
            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
              <div
                className="feedback-infomation"
                style={{ fontWeight: "bold" }}
              >
                Share your feedback!
              </div>
              {_.has(deliveryInformation, "customer_feedback") ? (
                <div className="submitted-container">
                  {_.get(deliveryInformation, "customer_feedback.text", "")}
                </div>
              ) : _.has(deliveryInformation, "fulfillment") &&
                show_feedback_form ? (
                <div className="card flex justify-content-center">
                  <InputTextarea
                    value={feedback_value}
                    placeholder="Type here..."
                    onChange={(e) => set_feedback_value(e.target.value)}
                    rows={5}
                    cols={30}
                    style={{ marginTop: 8 }}
                  />
                </div>
              ) : (
                <div className="feedback-activation-section">
                  <p className="activate-soon">Active Soon!</p>
                  <p className="margin-unset feedback-subheading">
                    Feedback form will be available post order completion.
                  </p>
                </div>
              )}
              {_.has(deliveryInformation, "customer_feedback") ? (
                <div className="space-between-container padding-top-8">
                  <div className="flex align-items-center">
                    {deliveryInformation?.customer_feedback?.requestCallback ? (
                      <img
                        src={disableTick}
                        alt="disable-tick"
                        width={"16px"}
                        height={"16px"}
                      />
                    ) : (
                      <span className="request-callback-box"></span>
                    )}
                    <span className="label-information">Request Callback</span>
                  </div>
                  <div className="submitted-feedback-button">
                    <img src={greenTickOutlined} alt="tick" /> Submitted
                  </div>
                </div>
              ) : (
                <div className="space-between-container padding-top-8">
                  <div className="flex align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                      // disabled={
                      //   _.has(deliveryInformation, "fulfillment") &&
                      //   _.get(deliveryInformation, "fulfillment.status", "") !==
                      //     "DELIVERED"
                      // }
                    ></Checkbox>
                    <span className="label-information">Request Callback</span>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      loading={isLoading}
                      disabled={
                        !feedback_value.length &&
                        !(
                          sad_image_highlight ||
                          smile_image_highlight ||
                          eye_heart_image_highlight
                        )
                      }
                      onClick={handleFeedback}
                      label="Submit"
                      style={{ fontSize: 14 }}
                      className="p-button-raised p-button-rounded submit-btn pidge-btn pidge-btn-disable"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default OrderDetails;
